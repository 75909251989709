import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from './hooks/useAppDispatch';
import { RootState } from './store';
import useLoadingStatus from './hooks/useLoadingStatus';
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import AppHeader from './components/AppHeader';
import AppNav from './components/AppNav';
import AuthPage from './pages/AuthPage';
import PartnersInfoPage from './pages/PartnersInfoPage';
import PartnersStatsPage from './pages/PartnersStatsPage';
import WithdrawalsPage from './pages/WithdrawalsPage';
import TransactionsPage from './pages/TransactionsPage';
import AdminsPage from './pages/AdminsPage';
import PromoCodesPage from './pages/PromoCodesPage';
import VouchersPage from './pages/VouchersPage';
import { checkToken } from './store/userSlice/userThunk';

function App() {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.user.token);
  const [loading, setLoading] = useLoadingStatus('pending');

  useEffect(() => {
    async function init() {
      try {
        setLoading('pending');
        await dispatch(checkToken());
        setLoading('fulfilled');
      } catch (error) {
        setLoading('rejected');
      }
    }

    init();
  }, [isLoggedIn]);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container">
      <ScrollToTop />
      {isLoggedIn && (
        <>
          <AppHeader />
          <AppNav />
        </>
      )}
      <main>
        <Switch>
          {isLoggedIn ? (
            <>
              <Route path="/partners-info">
                <PartnersInfoPage />
              </Route>
              <Route path="/partners-stats">
                <PartnersStatsPage />
              </Route>
              <Route path="/withdrawals">
                <WithdrawalsPage />
              </Route>
              <Route path="/transactions">
                <TransactionsPage />
              </Route>
              <Route path="/admins">
                <AdminsPage />
              </Route>
              <Route path="/promo-codes">
                <PromoCodesPage />
              </Route>
              <Route path="/vouchers">
                <VouchersPage />
              </Route>
              <Route exact path={'/'}>
                <p className="select-table">
                  {'👋, Yesim User\n\nSelect a table...'}
                </p>
              </Route>
            </>
          ) : (
            <Route path={'/login'}>
              <AuthPage />
            </Route>
          )}
          <Route render={() => <Redirect to={isLoggedIn ? '/' : '/login'} />} />
        </Switch>
      </main>
    </div>
  );
}

export default App;
