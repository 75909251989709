import React from 'react';
import styles from './AppHeader.module.scss';
import ArrowDropDownIcon from '../../assets/icons/ic_arrow_drop_down.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { logOut } from '../../store/userSlice/userThunk';

const AppHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const email = useSelector((state: RootState) => state.user.email);

  return (
    <div className={styles.container}>
      <p className={styles.label}>{'Affiliate Program admin panel'}</p>
      <div className={styles.dropdown}>
        <div className={styles.account}>
          <span className={styles.email}>{email}</span>
          <img src={ArrowDropDownIcon} className={styles.icon} />
        </div>
        <ul className={styles.menu}>
          <li className={styles.item} onClick={() => dispatch(logOut())}>
            Log Out
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppHeader;
